import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchRoleListApi } from '@/apis/member.api';
import type { Pagination } from '@/types/common/pagination.type';
import type { CreateRoleRequest, RolePermission, RoleType } from '@/types/role/role.request.type';

export const useRoleStore = defineStore('roleStore', () => {
  const roleList = ref<Pagination<RoleType[]>>({
    size: 0,
    page: 0,
    last: false,
    first: false,
    totalElements: 0,
    totalPages: 0,
    contents: []
  });
  const roleRequest = ref<CreateRoleRequest[]>([]);
  const rolePermissions = ref<RolePermission[]>([]);

  const fetchRoleList = async (
    groupId: string,
    params: {
      page?: number;
      size?: number;
    } = { page: 1, size: 10 }
  ) => {
    const { page, size } = params;

    const res = await fetchRoleListApi(groupId, {
      page,
      size
    });

    if (res) {
      roleList.value = res;
    }
  };

  return {
    roleRequest,
    rolePermissions,
    roleList,
    fetchRoleList
  };
});
